@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

/* Fix for dark mode transition */
html.dark {
  color-scheme: dark;
}

/* Smooth transitions for dark mode */
* {
  transition-property: color, background-color, border-color, 
                      text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* For syntax highlighting in code blocks */
.prose pre {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important; 
}

/* Remove focus outlines for mouse users, keep for keyboard users */
:focus:not(:focus-visible) {
  outline: none;
}

/* Only show focus rings for keyboard users */
:focus-visible {
  outline: 2px solid theme('colors.blue.500');
  outline-offset: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css */
.streak {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
